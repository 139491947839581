<template>
  <div class="custom-listing-table">
    <v-simple-table class="simple-table table-height">
      <template v-slot:default>
        <template v-if="$slots.thead">
          <slot name="thead"></slot>
        </template>
        <template v-if="dataLoading">
          <tr v-for="rcount in 5" :key="rcount">
            <td class="px-2 py-2" v-for="dcount in columnCount" :key="dcount">
              <v-skeleton-loader class="custom-skeleton" type="text">
              </v-skeleton-loader>
            </td>
          </tr>
        </template>
        <template v-else-if="$slots.tbody">
          <slot name="tbody"></slot>
        </template>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    dataLoading: {
      type: Boolean,
    },
    rowData: {
      type: Array,
    },
    columnCount: {
      type: Number,
      required: true,
    },
  },
};
</script>
